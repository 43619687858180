


$(document).on('turbolinks:load', function(){

    $('#lease-modal').on('shown.bs.modal', function() {

		ajaxRequest("#buildings", "/leases/properties", "GET");
		ajaxRequest("#properties", "/leases/property", "GET");


		$('input').on('focusout', function(event) {
	          
		    target_id = event.target.id;
		    target_value = $("#"+target_id ).val();
		    console.log("Value: ", target_value);
		    console.log("ID :", target_id);

		    //console.log(target_id)
		    if (target_value != null && target_id == "lease_advance_month_number"){
		    
 				lease_advance_month_number = parseFloat(target_value);
			    lease_rent_excl_tax = parseFloat($("#lease_rent_excl_tax").val());
			
			    lease_advance_amount = parseFloat((lease_advance_month_number * lease_rent_excl_tax));
			    
			  
			    
			    $("#lease_advance_amount").val(lease_advance_amount );

		    }

		    if (target_value != null && target_id == "lease_deposit_month_number"){
		    

			    lease_deposit_month_number = parseFloat(target_value);
			    lease_rent_excl_tax = parseFloat($("#lease_rent_excl_tax").val());
			
			    lease_deposit_amount = parseFloat((lease_deposit_month_number * lease_rent_excl_tax));
			    
			  
			    
			    $("#lease_deposit_amount").val(lease_deposit_amount );
			    

		    }

		    if (target_value != null && target_id == "lease_vat_rate"){
		    

			    lease_vat_rate = parseFloat(target_value);
			    lease_rent_excl_tax = parseFloat($("#lease_rent_excl_tax").val());
			    lease_irf_tf_rate = parseFloat($("#lease_irf_tf_rate").val());
			
			    lease_vat_amount = parseFloat((lease_vat_rate * lease_rent_excl_tax) / 100);
			    lease_irf_tf_amount = parseFloat((lease_irf_tf_rate * lease_rent_excl_tax) / 100);

			    
			  	lease_total_incl_tax = lease_rent_excl_tax + lease_vat_amount + lease_irf_tf_amount
			    
			    $("#lease_total_incl_tax").val(lease_total_incl_tax );
			    

		    }

		    if (target_value != null && target_id == "lease_irf_tf_rate"){
		    

			   
			    lease_irf_tf_rate = parseFloat(target_value);
			    lease_rent_excl_tax = parseFloat($("#lease_rent_excl_tax").val());
			    lease_vat_rate = parseFloat($("#lease_irf_tf_rate").val());
			
			    lease_vat_amount = parseFloat((lease_vat_rate * lease_rent_excl_tax) / 100);
			    lease_irf_tf_amount = parseFloat((lease_irf_tf_rate * lease_rent_excl_tax) / 100);

			    
			  	lease_total_incl_tax = lease_rent_excl_tax + lease_vat_amount + lease_irf_tf_amount
			    
			    $("#lease_total_incl_tax").val(lease_total_incl_tax );
			    

		    }
		});


    });





});

