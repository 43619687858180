

// Post request .
//window.ajaxPost

window.ajaxRequest = function ajaxRequest(source, route, verb){
	console.log("Source: ", source);
	$(source).on("change", function() {
	    $.ajax({
	        type: verb,
	        headers: {
	            'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
	            },
	        dataType: 'script',
	        url: route,
	        cache: 'false',
	        data: { data: $(source + ' option:selected').val()}
	    });
	});
};
