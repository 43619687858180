


$(document).on('turbolinks:load', function(){

    $('#mandate-modal').on('shown.bs.modal', function() {

		ajaxRequest("#buildings", "/mandates/properties", "GET");
		ajaxRequest("#properties", "/mandates/property", "GET");


		$('input').on('focusout', function(event) {
	          
		    target_id = event.target.id;
		    target_value = $("#"+target_id ).val();
		    console.log("Value: ", target_value);
		    console.log("ID :", target_id);

		    //console.log(target_id)
		    if (target_value != null && target_id == "mandate_commission_rate"){
		    

			    mandate_commission_rate = parseFloat(target_value);
			    property_value = parseFloat($("#mandate_property_value").val());
			    mandate_property_tax_amount = parseFloat($("#mandate_property_tax_amount").val());

			    commission_amount = parseFloat((property_value * mandate_commission_rate) / 100);
			    mandate_amount_incl_tax = property_value + commission_amount + mandate_property_tax_amount ;
			    mandate_net_amount = mandate_amount_incl_tax - commission_amount;
			    
			    //console.log("COMMISSION AMOUNT: ", commission_amount);
			    
			    $("#mandate_commission_amount").val(commission_amount);
			    $("#mandate_amount_incl_tax").val(mandate_amount_incl_tax);
			    $("#mandate_net_amount").val(mandate_net_amount);

		    }

		    if (target_value != null && target_id == "mandate_property_tax_rate"){
		    

			    mandate_property_tax_rate = parseFloat(target_value);
			    property_value = parseFloat($("#mandate_property_value").val());
			    mandate_commission_amount = parseFloat($("#mandate_commission_amount").val());

			    mandate_property_tax_amount = parseFloat((property_value * mandate_property_tax_rate) / 100);
			    mandate_amount_incl_tax = property_value + mandate_commission_amount + mandate_property_tax_amount ;
			    mandate_net_amount = mandate_amount_incl_tax - mandate_commission_amount;
			    
			    //console.log("COMMISSION AMOUNT: ", commission_amount);
			    
			    $("#mandate_property_tax_amount").val(mandate_property_tax_amount );
			    $("#mandate_amount_incl_tax").val(mandate_amount_incl_tax);
			    $("#mandate_net_amount").val(mandate_net_amount);

		    }
		});


    });





});

